import React, { FC } from 'react';
import * as styles from './badge.module.scss';

interface Props {
  backgroundColor?: string;
}

const Badge: FC<Props & React.HTMLAttributes<HTMLSpanElement>> = ({ children, className, backgroundColor }) => (
  <span className={`${styles.badge} ${className || ''}`} style={{ backgroundColor: backgroundColor }}>{children}</span>
);

export default Badge;